import React from 'react';

const MenuSection = ({ title, items }) => {
  return (
    <div className="menu-section mb-4 p-4 border-2 border-black rounded-lg">
      <h2 className="menu-title text-xl font-bold text-orange-600 mb-4">{title}</h2>
      <div className="menu-items">
        {items.map((item, index) => (
          <div key={index} className="menu-item grid grid-cols-2">
            <span className="menu-item-name text-lg">{item.name}</span>
            <span className="menu-item-price text-lg text-right">${item.price}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuSection;
