import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; // Import Font Awesome CSS

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-icons">
        <a href="https://m.me/joie.ragasa" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-facebook-messenger icon"></i>
        </a>
        <a href="https://www.tiktok.com/@joiecooks" target="_blank" rel="noopener noreferrer">
          <i className="fab fa-tiktok icon"></i>
        </a>
      </div>
      <span className="footer-text">50% DEPOSIT REQUIRED</span>
    </footer>
  );
};

export default Footer;
