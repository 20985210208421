import React from 'react';
import { Helmet } from 'react-helmet'; // Import react-helmet for SEO
import Header from './components/Header';
import MenuSection from './components/MenuSection';
import Footer from './components/Footer';
import BackgroundLayer from './components/BackgroundLayer'; // Import the BackgroundLayer component
import './App.css'; // Import the CSS file

const menuItems = {
  snacks: [
    { name: "Siomai 12 pcs", price: 10 },
    { name: "Chinese wonton 12 pcs", price: 10 }
  ],
  meals: [
    { name: "M Palabok (serves 4-6)", price: 45 },
    { name: "L Palabok (serves 8-10)", price: 60 },
    { name: "40 Spring rolls", price: 35 },
    { name: "60 Spring rolls", price: 50 }
  ],
  sweets: [
    { name: "Puto 12 pcs", price: 10 },
    { name: "Palitaw 12 pcs", price: 8 }
  ]
};

const App = () => {
  return (
    <div className="app-container">
      <Helmet>
        <title>Lady Kusina - Menu</title>
        <meta name="description" content="Treat yourself with delicious Filipino cuisine from Lady Kusina. Explore our menu and place your orders online." />
        <meta name="keywords" content="Filipino cuisine, Lady Kusina, menu, food delivery, Victoria" />
        <meta name="author" content="Christian Tapnio" />
        <link rel="canonical" href="https://www.ladykusina.com/" />
        <meta property="og:title" content="Lady Kusina - Treat yourself" />
        <meta property="og:description" content="Treat yourself with delicious Filipino cuisine from Lady Kusina. Explore our menu and place your orders online." />
        <meta property="og:image" content="https://www.ladykusina.com/images/og-image.jpg" />
        <meta property="og:url" content="https://www.ladykusina.com/" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Lady Kusina - Filipino Cuisine" />
        <meta name="twitter:description" content="Treat yourself with delicious Filipino cuisine from Lady Kusina. Explore our menu and place your orders online." />
        <meta name="twitter:image" content="https://www.ladykusina.com/images/twitter-card.jpg" />
      </Helmet>
      <BackgroundLayer /> {/* Add the background layer here */}
      <div className="left-column">
        {/* Add any content or decorative elements here */}
      </div>
      <div className="right-column">
        <div className="content-wrapper">
          <Header />
          <MenuSection title="SNACK" items={menuItems.snacks} />
          <MenuSection title="MEALS" items={menuItems.meals} />
          <MenuSection title="SWEETS" items={menuItems.sweets} />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;
