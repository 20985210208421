import React from 'react';
import './BackgroundLayer.css'; // Import the CSS for the background layer
import LittleBoob from '../assets/images/LittleBoob.svg';
import LineCurve from '../assets/images/LineCurve.svg';
import LineCurve2 from '../assets/images/LineCurve2.svg';
import Palabok from '../assets/images/Palabok.svg';
import Siomai from '../assets/images/Siomai.svg';
import Palitaw from '../assets/images/Palitaw.svg';
import Puto from '../assets/images/Puto.svg';
import LittleStar from '../assets/images/LittleStar.svg'; // Import the new image

const BackgroundLayer = () => {
  return (
    <div className="background-layer">
      <img src={LittleBoob} alt="Little Boob Decoration" className="little-boob" />
      <img src={LineCurve} alt="Line Curve Decoration" className="line-curve" />
      <img src={LineCurve2} alt="Line Curve 2 Decoration" className="line-curve-2" />
      <img src={Palabok} alt="Palabok Decoration" className="palabok" />
      <img src={Siomai} alt="Siomai Decoration" className="siomai" />
      <img src={Palitaw} alt="Palitaw Decoration" className="palitaw" />
      <img src={Puto} alt="Puto Decoration" className="puto" />
      <img src={LittleStar} alt="Little Star Decoration" className="little-star" />
    </div>
  );
};

export default BackgroundLayer;
