import React from 'react';

const Header = () => {
  return (
    <header className="text-center mb-8">
      <h1 className="text-4xl lxgw-wenkai-tc-regular text-black">
        LADYKUSINA{' '}
        <span className="lilita-one-regular text-orange-600">MENU</span>
      </h1>
    </header>
  );
};

export default Header;
